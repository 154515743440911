import React from 'react';
import { Row, Col } from '@d-lighted/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import moment from 'moment';

// import GoogleLogoSvg from 'images/ic_google_logo.svg';
import { useCurrentUser } from 'hooks/useCurrentUser';
import messages from '../messages';
import {
  CustomEventModal,
  CustomForm,
  ModalTitle,
  DragBar,
  // Icon,
  TitleLabel,
  InputField,
  ConfirmationDialogButton,
} from './styled';

function EventCreateDialog({
  onConfirm,
  timeSlot,
  timeFormat,
  isHomeBooking,
  ...otherProps
}) {
  const intl = useIntl();
  const [currentUser] = useCurrentUser();
  const formattedStartTime =
    (!isHomeBooking
      ? moment(timeSlot.start).format('MMMM Do ')
      : moment(timeSlot.start).format('M/D(ddd) ')) +
    moment(timeSlot.start).locale('en').format(timeFormat);
  const formattedEndTime = moment(timeSlot.end).locale('en').format(timeFormat);

  return (
    <CustomEventModal {...otherProps} height="auto">
      <Form
        onSubmit={onConfirm}
        render={({ handleSubmit }) => (
          <CustomForm onSubmit={handleSubmit}>
            <DragBar />
            <Row width="100%">
              <Col
                xs
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <ModalTitle isHomeBooking={isHomeBooking}>
                  {`${formattedStartTime} - ${formattedEndTime}`}
                  {isHomeBooking && (
                    <>
                      <br />
                      {timeSlot.resource.name}
                    </>
                  )}
                </ModalTitle>
              </Col>
              {/* This icon is not currently required, hence it is disabled for now. */}
              {/* <Col display="none"> */}
              {/*  <Icon src={GoogleLogoSvg} /> */}
              {/* </Col> */}
            </Row>

            {!currentUser.company.is_subcompany && (
              <Row width="100%" mt="13px">
                <Col xs pl="16px" pr="16px">
                  <Row>
                    <Col>
                      <TitleLabel>
                        <FormattedMessage {...messages.titleLabel} />
                      </TitleLabel>
                    </Col>
                  </Row>
                  <Row mt="9px">
                    <Col xs>
                      <Field
                        id="title"
                        name="title"
                        component={InputField}
                        placeholder=""
                        initialValue={intl.formatMessage(messages.meeting)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

            <Row mt="25px" width="100%" justifyContent="center">
              <Col width="77%">
                <ConfirmationDialogButton
                  className="confirm_button"
                  type="submit"
                >
                  <FormattedMessage {...messages.reserveButton} />
                </ConfirmationDialogButton>
              </Col>
            </Row>
          </CustomForm>
        )}
      />
    </CustomEventModal>
  );
}

EventCreateDialog.propTypes = {
  onConfirm: PropTypes.oneOfType([PropTypes.func]).isRequired,
  timeSlot: PropTypes.oneOfType([PropTypes.object]).isRequired,
  timeFormat: PropTypes.string.isRequired,
  isHomeBooking: PropTypes.bool,
};

EventCreateDialog.defaultProps = {
  isHomeBooking: false,
};

export default EventCreateDialog;
