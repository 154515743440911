import moment from 'moment';

export function isOverlapping(events, slotInfo) {
  const eventsInARoom = events.filter(event => {
    return slotInfo.resourceId === event.resourceId;
  });

  return eventsInARoom.some(event => {
    return (
      moment(slotInfo.start).isBetween(
        moment(event.start),
        moment(event.end),
      ) ||
      moment(slotInfo.end).isBetween(moment(event.start), moment(event.end)) ||
      moment(event.start).isBetween(
        moment(slotInfo.start),
        moment(slotInfo.end),
      )
    );
  });
}

export const checkRoomIsBusy = (resourceId, events) => {
  const eventsInARoom = events.filter(event => {
    return resourceId === event.resourceId;
  });
  return eventsInARoom.some(event => event.checkedInAt && !event.checkedOutAt);
};
