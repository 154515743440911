import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';

import DropDownButton from 'components/Buttons/DropDownButton';
import messages from 'components/BigCalendar/messages';

export default function CalendarHeaderDropdown({ filterByTime }) {
  function handleButtonClick(time) {
    filterByTime(time);
  }

  const items = [
    {
      text: <FormattedMessage {...messages.notSpecified} />,
      onClick: () => handleButtonClick(null),
    },
    {
      text: <FormattedMessage {...messages.fifteenMins} />,
      onClick: () => handleButtonClick(15),
    },
    {
      text: <FormattedMessage {...messages.thirtyMins} />,
      onClick: () => handleButtonClick(30),
    },
    {
      text: <FormattedMessage {...messages.sixtyMins} />,
      onClick: () => handleButtonClick(60),
    },
  ];

  return (
    <DropDownButton
      buttonText={<FormattedMessage {...messages.filterButtonText} />}
      items={items}
    />
  );
}

CalendarHeaderDropdown.propTypes = {
  filterByTime: PropTypes.func.isRequired,
};
