import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Label } from '@d-lighted/design-system';

import * as colors from 'constants/colors';
import messages from '../messages';
import {
  DayCardWrapper,
  DayCircle,
  DayText,
  TimeListWrapper,
  TimeRangeText,
  RoomDetailLabel,
  ReserveButton,
} from './styled';

function FilteredDayCard({ availableDate, onReserveTimeSlot }) {
  const { date, timeslots } = availableDate;

  return (
    <DayCardWrapper>
      <Row alignItems="center">
        <Col>
          <DayCircle>
            {moment(date).format('DD')}
            <DayText>{moment(date).format('dd')}</DayText>
          </DayCircle>
        </Col>
        <Col pl="0">
          <Label fontSize="14px" color={colors.darkBlue} fontWeight="normal">
            {moment(date).format('MMMM')}
          </Label>
        </Col>
      </Row>

      <TimeListWrapper>
        {timeslots.map((timeSlot, index) => (
          <Row pt="20px" key={timeSlot.resource.uid + timeSlot.start}>
            <Col xs={8} pr="2px">
              <TimeRangeText>{`${moment(timeSlot.start).format(
                'HH:mm',
              )}-${moment(timeSlot.end).format('HH:mm')}`}</TimeRangeText>
              <RoomDetailLabel>{`${timeSlot.resource.name}`}</RoomDetailLabel>
            </Col>
            <Col xs={4} pl="0">
              <ReserveButton
                onClick={() => onReserveTimeSlot({ ...timeSlot, date, index })}
              >
                <FormattedMessage {...messages.reserve} />
              </ReserveButton>
            </Col>
          </Row>
        ))}
      </TimeListWrapper>
    </DayCardWrapper>
  );
}

FilteredDayCard.propTypes = {
  availableDate: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onReserveTimeSlot: PropTypes.func.isRequired,
};

export default FilteredDayCard;
