import React, { useState, useEffect } from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import humps from 'lodash-humps';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router';
import { useSnackbar } from 'react-simple-snackbar';
import { FormattedMessage, useIntl } from 'react-intl';

import * as color from 'constants/colors';
import { isOverlapping } from 'utils/eventUtils';
import { getCurrentUser } from 'utils/userUtils';
import * as commonUtil from 'utils/commonUtils';
import { timeRangeFormat } from 'utils/momentUtils';
import * as dateUnitTypes from 'constants/dateUnitTypes';
import { CalendarHeader } from './CalendarHeader';
import EventContent from './EventContent';
import EventCreateDialog from './EventCreateDialog';
import EventDetailsDialog from './EventDetailsDialog';
import BookingSuccessDialog from './BookingSuccessDialog';
import BookingDeleteSuccessDialog from './BookingDeleteSuccessDialog';
import BookingErrorDialog from './BookingErrorDialog';
import messages from '../messages';
import { MobileDateHeaderBox } from './styled';

const snackBarOptions = {
  position: 'top-center',
  style: {
    backgroundColor: color.errorRed,
    border: 'none',
    color: color.white,
    fontSize: '14px',
    textAlign: 'center',
  },
  closeStyle: {
    display: 'none',
  },
};

function CalendarBody({
  resourceMap,
  events,
  postCalendarEvent,
  resourceId: resId,
  setTempEvent,
  tempEvent,
  setDate,
  deleteCalendarEvent,
  setSelectedDate,
  selectedDate,
}) {
  const history = useHistory();
  const [openSnackbar] = useSnackbar(snackBarOptions);
  const localizer = momentLocalizer(moment);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [eventCreateDialogOpen, setEventCreateDialogOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [eventDetailsDialogOpen, setEventDetailsDialogOpen] = useState(false);
  const [bookingSuccessDialogOpen, setBookingSuccessDialogOpen] = useState(
    false,
  );
  const [
    bookingDeleteSuccessDialogOpen,
    setBookingDeleteSuccessDialogOpen,
  ] = useState(false);
  const [bookingErrorDialogOpen, setBookingErrorDialogOpen] = useState(false);
  const [eventErrorMessage, setEventErrorMessage] = useState('');
  const currentUser = getCurrentUser();
  const camelizedUser = humps(currentUser);
  const { timeConvention } = camelizedUser?.company;
  // 積水ハウス用, companyからidを出すと命名的にわからなくなるのでcompanyから直接取る
  const isSekisuiCompanyUser = camelizedUser?.company?.id === 6885;
  const timeFormat = commonUtil.getTimeFormat(timeConvention);
  const [now, setNow] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    setNow(moment().subtract(1, dateUnitTypes.HOURS).toDate());
  }, []);

  const toggleEventCreateDialogOpen = () =>
    setEventCreateDialogOpen(!eventCreateDialogOpen);
  const toggleEventDetailsDialogOpen = () =>
    setEventDetailsDialogOpen(!eventDetailsDialogOpen);
  const toggleBookingSuccessDialogOpen = () => {
    return setBookingSuccessDialogOpen(!bookingSuccessDialogOpen);
  };
  const toggleBookingErrorDialogOpen = () =>
    setBookingErrorDialogOpen(!bookingErrorDialogOpen);

  const toggleBookingDeleteSuccessDialogOpen = () => {
    return setBookingDeleteSuccessDialogOpen(!bookingDeleteSuccessDialogOpen);
  };

  const closeDialog = (dialogType, apiCallStart) => {
    if (dialogType === 'create') {
      setEventCreateDialogOpen(false);
      if (!apiCallStart) setTempEvent({});
    }
    if (dialogType === 'details') setEventDetailsDialogOpen(false);
  };

  const formats = {
    timeGutterFormat: date => moment(date).locale('en').format(timeFormat),
    selectRangeFormat: range => timeRangeFormat(range, timeFormat, 'en'),
    eventTimeRangeFormat: () => '',
  };

  const generateEventStatus = (checkedInAt, checkedOutAt) => {
    if (checkedInAt && checkedOutAt) {
      return 'done';
    }
    if (checkedInAt && !checkedOutAt) {
      return 'busy';
    }
    return 'future';
  };

  const eventWrapperStyle = ({ checkedInAt, checkedOutAt, temporary }) => {
    let customStyle = {
      border: 0,
      fontSize: '12px',
      padding: '8px 8px',
      flexFlow: 'row wrap',
    };
    switch (generateEventStatus(checkedInAt, checkedOutAt)) {
      case 'done':
        customStyle = {
          ...customStyle,
          backgroundColor: color.doneEventGray,
        };
        break;
      case 'busy':
        customStyle = {
          ...customStyle,
          backgroundColor: color.busyEvent,
          color: color.white,
        };
        break;
      case 'future':
      default:
        customStyle = {
          ...customStyle,
          backgroundColor: color.incomingEvent,
          border: `solid 0.5px ${color.incomingEvent}`,
          color: color.white,
        };
        break;
    }
    if (temporary) {
      customStyle = {
        ...customStyle,
        backgroundColor: color.primaryGreen,
        color: color.white,
        border: 0,
      };
    }
    return {
      style: customStyle,
    };
  };

  const slotWrapperStyle = date => {
    return {
      className: date.toISOString(),
      style: {
        background: 'white',
      },
    };
  };

  const handleNavigate = date => {
    setSelectedDate(date);
    setDate(date);
  };

  const handleSelectSlot = slotInfo => {
    if (isOverlapping(events, slotInfo)) {
      openSnackbar(<FormattedMessage {...messages.overlap} />);
      return;
    }

    setSelectedSlot(slotInfo);
    setTempEvent(slotInfo, timeFormat);
    setEventCreateDialogOpen(true);
  };

  const handleResult = (result, message) => {
    if (result === 'success') setBookingSuccessDialogOpen(true);
    if (result === 'error') {
      setBookingErrorDialogOpen(true);
      setEventErrorMessage(message);
    }
  };

  const handleEventReserve = values => {
    postCalendarEvent(
      {
        start: selectedSlot.start,
        end: selectedSlot.end,
        name: values.title,
      },
      resId,
      handleResult,
      setTempEvent,
    );
    closeDialog('create', true);
  };

  const getResourceTitle = event => {
    const selectedResource = resourceMap.filter(
      resource => resource.resourceId === event.resourceId,
    );
    return selectedResource[0].resourceTitle;
  };

  const handleSelectEvent = event => {
    const resourceTitle = getResourceTitle(event);
    setSelectedEvent({ ...event, resourceTitle });
    setEventDetailsDialogOpen(true);
  };

  const handleEventDelete = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(intl.formatMessage(messages.confirmDelete))) {
      const { id, microsoftCalUid } = selectedEvent;
      deleteCalendarEvent(microsoftCalUid || id, () => {
        setBookingDeleteSuccessDialogOpen(true);
      });
      closeDialog('details');
    }
  };

  const eventsToPass = () => {
    return !isEmpty(tempEvent) ? [...events, tempEvent] : events;
  };

  const handleReturnButtonClick = status => {
    if (status === 'success') {
      return toggleBookingSuccessDialogOpen();
    }

    return history.push('/');
  };

  const handleDeleteReturnButtonClick = status => {
    if (status === 'success') {
      return toggleBookingDeleteSuccessDialogOpen();
    }

    return null;
  };

  return (
    <MobileDateHeaderBox
      today={new Date().getDate() === selectedDate.getDate()}
    >
      <Calendar
        min={
          new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate(),
            isSekisuiCompanyUser ? 9 : 0,
            0,
            0,
          )
        }
        max={
          new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate(),
            isSekisuiCompanyUser ? 18 : 23,
            isSekisuiCompanyUser ? 0 : 59,
            0,
          )
        }
        localizer={localizer}
        events={eventsToPass()}
        defaultView={Views.DAY}
        views={['day']}
        defaultDate={selectedDate}
        resources={resourceMap}
        resourceIdAccessor="resourceId"
        resourceTitleAccessor="resourceTitle"
        formats={formats}
        components={{
          toolbar: CalendarHeader,
          event: EventContent,
        }}
        eventPropGetter={eventWrapperStyle}
        slotPropGetter={slotWrapperStyle}
        onNavigate={handleNavigate}
        selectable
        onSelectSlot={handleSelectSlot}
        longPressThreshold={200}
        onSelectEvent={handleSelectEvent}
        scrollToTime={now}
      />

      <EventCreateDialog
        timeSlot={selectedSlot}
        onConfirm={handleEventReserve}
        timeFormat={timeFormat}
        isOpen={eventCreateDialogOpen}
        toggleModal={toggleEventCreateDialogOpen}
        onBackgroundClick={() => closeDialog('create')}
        onEscapeKeydown={() => closeDialog('create')}
      />

      {eventDetailsDialogOpen && (
        <EventDetailsDialog
          selectedEvent={selectedEvent}
          onCancel={() => closeDialog('details')}
          timeFormat={timeFormat}
          generateEventStatus={generateEventStatus}
          isOpen={eventDetailsDialogOpen}
          toggleModal={toggleEventDetailsDialogOpen}
          onBackgroundClick={() => closeDialog('details')}
          onEscapeKeydown={() => closeDialog('details')}
          onDelete={handleEventDelete}
        />
      )}

      <BookingDeleteSuccessDialog
        onButtonClick={() => handleDeleteReturnButtonClick('success')}
        isOpen={bookingDeleteSuccessDialogOpen}
        toggleModal={toggleBookingDeleteSuccessDialogOpen}
        onBackgroundClick={toggleBookingDeleteSuccessDialogOpen}
        onEscapeKeydown={toggleBookingDeleteSuccessDialogOpen}
      />

      <BookingSuccessDialog
        onButtonClick={() => handleReturnButtonClick('success')}
        isOpen={bookingSuccessDialogOpen}
        toggleModal={toggleBookingSuccessDialogOpen}
        onBackgroundClick={toggleBookingSuccessDialogOpen}
        onEscapeKeydown={toggleBookingSuccessDialogOpen}
        timeSlot={
          selectedSlot != null && selectedSlot?.slots != null
            ? {
                date: selectedSlot?.slots[0],
                start: selectedSlot?.slots[0],
                end: selectedSlot?.slots[selectedSlot.slots?.length - 1],
              }
            : null
        }
      />

      <BookingErrorDialog
        message={eventErrorMessage}
        onButtonClick={() => handleReturnButtonClick('error')}
        isOpen={bookingErrorDialogOpen}
        toggleModal={toggleBookingErrorDialogOpen}
        onBackgroundClick={toggleBookingErrorDialogOpen}
        onEscapeKeydown={toggleBookingErrorDialogOpen}
      />
    </MobileDateHeaderBox>
  );
}

CalendarBody.propTypes = {
  resourceMap: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  events: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  postCalendarEvent: PropTypes.func.isRequired,
  resourceId: PropTypes.string.isRequired,
  setTempEvent: PropTypes.func.isRequired,
  tempEvent: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setDate: PropTypes.func.isRequired,
  deleteCalendarEvent: PropTypes.func.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  selectedDate: PropTypes.string.isRequired,
};

export default CalendarBody;
