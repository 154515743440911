import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { Col, Row, ErrorText, Input } from '@d-lighted/design-system';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Logo } from 'components/SignInHeader/components/styled';
import * as imageUtil from 'utils/imageUtil';
import logo from 'images/img_logo_rooms.svg';
import { useSignIn } from './api';
import { useValidation } from '../../hooks/useValidation/index';
import { useLocale } from '../../hooks/useLocale';
import messages from './messages';
import {
  WelcomeContainer,
  WelcomeParagraph,
  WelcomeLogin,
  FormBody,
  Label,
  LogoContainer,
  BottomTextBody,
  ForgotPasswordBody,
  LoginButton,
  LeftCol,
  RightCol,
  BlueLabel,
  LoginFormRow,
  CenteredCol,
  PromotionHeading,
} from './components/styled';

const SignIn = ({ intl, useNotify, error, setCurrentUser }) => {
  const notify = useNotify(intl);
  const [, setLocale] = useLocale();
  const location = useLocation();
  const { required, email } = useValidation(intl);

  const composeValidators = (...validators) => value =>
    validators.reduce(
      (errors, validator) => errors || validator(value),
      undefined,
    );

  const {
    signIn,
    promotionLinkState: promotionLink,
    promotionTitleState: promotionTitle,
    fetchPromotionLink,
    fetchPromotionTitle,
    validateOnetimeToken,
  } = useSignIn(
    { notify, setLocale },
    setCurrentUser,
    location?.state && location?.state,
  );

  useEffect(() => {
    fetchPromotionLink();
    fetchPromotionTitle();

    const formattedUrl = new URLSearchParams(window.location.search);
    if (formattedUrl.get('one_time_token') && formattedUrl.get('client_id')) {
      validateOnetimeToken(
        formattedUrl.get('one_time_token'),
        formattedUrl.get('client_id'),
      );
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Row height="100vh" margin="0">
      <LeftCol xs={12} sm={6}>
        <Form
          onSubmit={signIn}
          render={({ handleSubmit }) => (
            <LoginFormRow>
              <CenteredCol xs={12}>
                <form onSubmit={handleSubmit}>
                  <LogoContainer>
                    <Logo src={logo} alt="RECEPTIONIST" />
                  </LogoContainer>
                  <WelcomeContainer>
                    <WelcomeLogin>
                      <FormattedMessage {...messages.login} />
                    </WelcomeLogin>
                    <WelcomeParagraph>
                      <FormattedMessage {...messages.welcome} />
                    </WelcomeParagraph>
                  </WelcomeContainer>
                  <FormBody>
                    <Row mb="17px">
                      <Col xs>
                        <Row mb="13px">
                          <Col xs>
                            <Label fontSize="14px">
                              <FormattedMessage {...messages.email} />
                            </Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs>
                            <Field
                              id="email"
                              name="email"
                              component={Input}
                              validate={composeValidators(required, email)}
                              placeholder=""
                              height="50px"
                              borderColor="#e9eff4"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs>
                        <Row mb="13px">
                          <Col xs>
                            <Label fontSize="14px">
                              <FormattedMessage {...messages.password} />
                            </Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs>
                            <Field
                              id="password"
                              name="password"
                              type="password"
                              component={Input}
                              validate={required}
                              placeholder=""
                              height="50px"
                              borderColor="#e9eff4"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </FormBody>

                  {error && (
                    <Row mb="20px">
                      <Col xs>
                        <ErrorText>{error}</ErrorText>
                      </Col>
                    </Row>
                  )}

                  <BottomTextBody>
                    <ForgotPasswordBody>
                      <BlueLabel
                        isCursor
                        fontSize="14px"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_WEB_PATH}password/restore`,
                            '_self',
                          )
                        }
                      >
                        <FormattedMessage {...messages.forgotPassword} />
                      </BlueLabel>
                      <br />
                    </ForgotPasswordBody>
                  </BottomTextBody>

                  <LoginButton type="submit">
                    <FormattedMessage {...messages.login} />
                  </LoginButton>
                </form>
              </CenteredCol>
            </LoginFormRow>
          )}
        />
      </LeftCol>
      <RightCol xs={12} sm={6}>
        <Row>
          <Col xs={12}>
            <PromotionHeading>{promotionTitle}</PromotionHeading>
          </Col>
          <CenteredCol xs={12}>
            {imageUtil.getProductPromotionImage('reception', promotionLink)}
          </CenteredCol>
        </Row>
      </RightCol>
    </Row>
  );
};

SignIn.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  useNotify: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};

export default injectIntl(SignIn);
