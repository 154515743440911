import styled from 'styled-components';
import {
  Button,
  Col,
  Modal,
  Label,
  Input,
  Heading,
} from '@d-lighted/design-system';

import Text from 'components/Texts/Text16';
import * as color from 'constants/colors';

export const HeadingText = styled(Heading)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${color.blackTitle};
`;

export const HeadingMonth = styled(Heading)`
  font-weight: bold;
  font-size: 12px;
  color: ${color.grayThin3};
  padding-top: 6px;
`;

export const DateSelector = styled.div`
  width: 12vw;
  height: 71px;
  background: ${props => {
    if (props.isSelected) return color.brandBlue;
    if (props.isCurrent) return color.todayIndicator;
    return color.white;
  }};
  border-radius: 24px;
  cursor: pointer;
  ${props => {
    if (props.isPast) return 'pointer-events: none;';
    return '';
  }}
`;

export const Day = styled.p`
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  color: ${({ isSelected, isCurrent, isPast }) => {
    if (isSelected) return color.white;
    if (isCurrent) return color.darkBlue;
    if (isPast) return color.grayShade3;
    return color.deepBlue;
  }};
  margin-top: 10px;
`;

export const Date = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin-top: 8px;
  color: ${({ isSelected, isCurrent, isPast }) => {
    if (isSelected) return color.white;
    if (isCurrent) return color.darkBlue;
    if (isPast) return color.grayShade3;
    return color.deepBlue;
  }};
`;

export const CalendarWrapper = styled.div`
  .dropdown__content {
    z-index: 1;
  }
`;

export const ResourceHeaderText = styled(Text)`
  line-height: 2;
`;

export const CustomResourceHeader = styled.div`
  background-color: ${color.white};
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px 10px 0 0;
`;

export const CustomModal = styled(Modal)`
  border-radius: 20px;
`;

export const CustomEventModal = styled(Modal)`
  border-radius: 36px;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

export const CustomForm = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 28px;
  .confirm_button {
    background-color: ${color.brandBlue};
  }
  .cancel_button {
    color: ${color.brandBlue} !important;
    border: 2px solid ${color.brandBlue};
  }
  .space_top {
    padding: 20px 0;
  }
`;

export const DragBar = styled.div`
  background-color: ${color.grayShade3};
  width: 48px;
  height: 6px;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 14px;
`;

export const ModalTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: ${props => (props.isHomeBooking ? '26px' : '19px')};
  color: ${color.darkBlue};
  text-align: ${props => props.isHomeBooking && 'center'};
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const ConfirmationDialogButton = styled(Button)`
  width: 100%;
  height: 47px;
  border-radius: 24px;
  font-weight: 600;
`;

export const CancelDialogButton = styled(Button)`
  width: 100%;
  height: 47px;
  border-radius: 24px;
  font-weight: 600;
  color: ${color.brandBlue};
  border: 2px solid ${color.brandBlue};
`;

export const RoomCol = styled(Col)`
  background-color: ${color.grayShade2};
  border-radius: 10px;
  padding: 13px 20px;
`;

export const TitleLabel = styled(Label)`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${color.darkBlue};
`;

export const InputField = styled(Input)`
  height: 48px;
  background: ${color.lightGray};
  border-radius: 11px;
  border-color: ${color.lightGray};
`;

export const DialogContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CircleIcon = styled.div`
  width: 82px;
  height: 82px;
  border-radius: 56px;
  margin-top: ${props => (props.isHomeBooking ? '24px' : '42px')};
  background: ${props => (props.success ? color.brandBlue : color.darkRed)};
  box-shadow: 0px 10px 30px
    ${props => (props.success ? color.transparentBlue : color.transparentRed)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainStatusText = styled.p`
  font-weight: bold;
  font-size: 22px;
  line-height: 36px;
  color: ${color.darkText};
  text-align: center;
`;

export const SubText = styled(Label)`
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: ${color.grayThin3};
  text-align: center;
  display: block;
`;

export const EventDetailsContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ColorIndicator = styled.div`
  border-radius: 4px;
  height: 20px;
  width: 20px;
  background-color: ${props => props.color};
  border: ${props => props.border || null};
`;

export const MobileDateHeaderBox = styled.div`
  height: 100%;
  width: 100%;
  .rbc-day-slot {
    border-top: ${p => (p.today ? 'none' : 'solid 1px #ddd')};
  }
`;
