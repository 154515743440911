import axios from 'axios';
import { getSubdomain } from 'utils/userUtils';

function useOneTimeToken() {
  const requestOneTimeToken = async () => {
    const result = await axios.post('auth/one_time_token');

    if (result.status < 300) {
      return result.data?.token;
    }

    return null;
  };

  const openWeb2RouteWithToken = async url => {
    // TODO: Commented this for the future
    /* const token = await requestOneTimeToken();

    if (token == null) return; // TODO: Maybe add here some error

    const params = {
      token,
      client_id: (JSON.parse(localStorage.getItem('auth_headers')) || {})
        .client,
      redirect: url,
    };
    window.open(
      `${process.env.REACT_APP_WEB_PATH}/sign_in?${new URLSearchParams(
        params,
      ).toString()}`,
      '_blank',
    ); */
    window.open(
      `${window.location.protocol}//${getSubdomain()}${
        process.env.REACT_APP_WEB_PATH
      }${url}`,
      '_blank',
    );
  };

  return { requestOneTimeToken, openWeb2RouteWithToken };
}

export { useOneTimeToken };
