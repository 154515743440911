import React, { useState, useEffect, useRef } from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import PropTypes from 'prop-types';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import humps from 'lodash-humps';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'react-simple-snackbar';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';

import * as color from 'constants/colors';
import { isOverlapping, checkRoomIsBusy } from 'utils/eventUtils';
import { getCurrentUser } from 'utils/userUtils';
import * as commonUtil from 'utils/commonUtils';
import { timeRangeFormat } from 'utils/momentUtils';
import * as dateUnitTypes from 'constants/dateUnitTypes';
import ResourceSettingGuideView from 'components/ResourceSettingGuideView';
import { CalendarHeader } from './CalendarHeader';
import ResourceHeader from './ResourceHeader';
import EventContent from './EventContent';
import EventCreatePopover from './EventCreatePopover';
import EventDetailsPopover from './EventDetailsPopover';
import LoadingView from './LoadingView';
import messages from '../messages';
import FullScreenHeader from './FullScreenHeader';
import { MainWrapper, DateHeaderBox } from './styled';

const snackBarOptions = {
  position: 'bottom-center',
  style: {
    backgroundColor: color.errorRed,
    border: 'none',
    color: color.white,
    fontSize: '14px',
    textAlign: 'center',
  },
  closeStyle: {
    display: 'none',
  },
};

export default function CalendarBody({
  resourceMap,
  events,
  postCalendarEvent,
  filterByTime,
  filteringTime,
  filteredResources,
  filteredEvents,
  downloadMeetingRoomCsv,
  setTempEvent,
  tempEvent,
  isLoading,
  deleteCalendarEvent,
  setDate,
  fetchBuildings,
  buildings,
  setBuilding,
  selectedBuilding,
  fetchResourceAndEvent,
  fullscreenState,
}) {
  const localizer = momentLocalizer(moment);
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [openSnackbar] = useSnackbar(snackBarOptions);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [eventCreatePopoverOpen, setEventCreatePopoverOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [eventDetailsPopoverOpen, setEventDetailsPopoverOpen] = useState(false);
  const currentUser = getCurrentUser();
  const camelizedUser = humps(currentUser);
  const { timeConvention } = camelizedUser?.company;
  // 積水ハウス用, companyからidを出すと命名的にわからなくなるのでcompanyから直接取る
  const isSekisuiCompanyUser = camelizedUser?.company?.id === 6885;
  const timeFormat = commonUtil.getTimeFormat(timeConvention);
  const [now, setNow] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();

  // to persist localstorage value between re renders so that infinite re-renders are prevented
  const localEditConfig = useRef(
    JSON.parse(localStorage.getItem('roomsWebEditConfig')),
  );

  const [editMode, setEditMode] = useState(false);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    const localEditUser = localEditConfig?.current?.user;
    const localResources = localEditConfig?.current?.resources;

    // only use locally stored configuration if it is the same user
    if (localEditUser && localEditUser === currentUser.id) {
      // checking for new resources and adding/removing resources while maintaining local resources's visibility state
      let newArr = [];
      if (resourceMap?.length > 0) {
        newArr = resourceMap.map(item => {
          if (localResources?.length > 0) {
            const foundObj = localResources.find(element => {
              return item.resourceId === element.resourceId;
            });

            if (foundObj) {
              return {
                ...item,
                visible: foundObj.visible,
              };
            }
          }
          return item;
        });
      }
      setResources(newArr);
    } else {
      setResources(resourceMap);
    }
  }, [resourceMap, currentUser.id]);

  const handleEditModeChange = () => {
    setEditMode(!editMode);

    // setting filteringTime to null so that it does not affect edit mode
    filterByTime(null);
  };

  const handleResourceVisibleChange = resourceId => {
    // changing visiblity of each resource
    const newArr = resources.map(item => {
      if (item.resourceId === resourceId) {
        return {
          ...item,
          visible: !item.visible,
        };
      }
      return item;
    });
    setResources(newArr);
  };

  const handleSaveChanges = () => {
    // storing invisibleResources to local storage and ref after clicking 'Save Changes' button
    let invisibleResources = [];
    if (resources?.length > 0) {
      invisibleResources = resources
        .filter(item => !item.visible)
        .map(item => {
          return {
            resourceId: item.resourceId,
            visible: item.visible,
          };
        });
    }

    const localObject = {
      user: currentUser.id,
      resources: invisibleResources,
    };
    localStorage.setItem('roomsWebEditConfig', JSON.stringify(localObject));
    localEditConfig.current = localObject;
  };

  const handleResources = () => {
    let visibleResources = [];
    const filteredVisibleResources = [];

    // showing filtered resources when filteringTime is received
    if (filteringTime) {
      if (resources?.length > 0) {
        visibleResources = resources.filter(item => item.visible);

        // filtering visible resources only
        if (filteredResources?.length > 0) {
          filteredResources.map(item => {
            if (visibleResources?.length > 0) {
              visibleResources.map(el => {
                if (item.resourceId === el.resourceId) {
                  filteredVisibleResources.push(el);
                }
                return null;
              });
            }
            return null;
          });
        }
      }
      return filteredVisibleResources;
    }

    // showing all resources with their visibility state
    if (editMode) {
      return resources;
    }

    // showing visible resources only
    if (resources?.length > 0) {
      visibleResources = resources.filter(item => item.visible);
    }

    return visibleResources;
  };

  const formats = {
    timeGutterFormat: date => moment(date).locale('en').format(timeFormat),
    selectRangeFormat: range => timeRangeFormat(range, timeFormat, 'en'),
    eventTimeRangeFormat: () => '',
    eventTimeRangeStartFormat: () => '',
    eventTimeRangeEndFormat: () => '',
  };

  const closePopover = (popoverType, apiCallStart) => {
    if (popoverType === 'create') {
      setEventCreatePopoverOpen(false);
      if (!apiCallStart) setTempEvent({});
    }
    if (popoverType === 'details') setEventDetailsPopoverOpen(false);
  };

  const generateEventStatus = (checkedInAt, checkedOutAt) => {
    if (checkedInAt && checkedOutAt) {
      return 'done';
    }
    if (checkedInAt && !checkedOutAt) {
      return 'busy';
    }
    return 'future';
  };

  const eventWrapperStyle = ({
    checkedInAt,
    checkedOutAt,
    occupyNew,
    temporary,
    resourceId,
    id,
  }) => {
    let customStyle = {
      border: 0,
      fontSize: '12px',
      padding: '8px 8px',
      flexFlow: 'row wrap',
    };

    if (editMode) {
      customStyle = {
        ...customStyle,
        visibility: 'hidden',
      };
    }

    switch (generateEventStatus(checkedInAt, checkedOutAt)) {
      case 'done':
        customStyle = {
          ...customStyle,
          backgroundColor: color.doneEventGray,
        };
        break;
      case 'busy':
        customStyle = {
          ...customStyle,
          backgroundColor: color.busyEvent,
          color: color.white,
        };
        break;
      case 'future':
      default:
        customStyle = {
          ...customStyle,
          backgroundColor: color.incomingEvent,
          border: `solid 0.5px ${color.incomingEvent}`,
          color: color.white,
        };
        break;
    }
    if (occupyNew || temporary) {
      customStyle = {
        ...customStyle,
        backgroundColor: color.primaryGreen,
        color: color.white,
        border: 0,
      };
    }
    return {
      className: `${resourceId}.${id}`,
      style: customStyle,
    };
  };

  const isRoomBusy = resourceId => checkRoomIsBusy(resourceId, events);

  const slotWrapperStyle = (date, resourceId) => {
    let resourceObj;

    // changing slot background according to visible value of resource
    if (resources?.length) {
      resources.find(item => {
        if (item.resourceId === resourceId && item.visible) {
          resourceObj = item;
        }
        return null;
      });
    }

    const handleBackgroundColor = () => {
      if (isRoomBusy(resourceId) && !editMode) {
        return color.busyBGGray;
      }

      if (editMode && !resourceObj?.visible) {
        return color.lightGray;
      }

      return color.white;
    };

    return {
      className: date.toISOString(),
      style: {
        background: handleBackgroundColor(),
      },
    };
  };

  const handleSelectSlot = slotInfo => {
    if (editMode) {
      handleResourceVisibleChange(slotInfo?.resourceId);
      return;
    }

    if (isOverlapping(events, slotInfo)) {
      openSnackbar(<FormattedMessage {...messages.overlap} />);
      return;
    }

    setSelectedSlot(slotInfo);
    setTempEvent(slotInfo, timeFormat);
    setEventCreatePopoverOpen(true);
  };

  const handleEventReserve = values => {
    postCalendarEvent(
      {
        start: selectedSlot.start,
        end: selectedSlot.end,
        name: values.title,
      },
      selectedSlot.resourceId,
      setTempEvent,
    );
    closePopover('create', true);
  };

  const getResourceTitle = event => {
    const selectedResource = resourceMap.filter(
      resource => resource.resourceId === event.resourceId,
    );
    return selectedResource[0].resourceTitle;
  };

  const handleSelectEvent = event => {
    if (editMode) {
      handleResourceVisibleChange(event?.resourceId);
      return;
    }
    if (!event.occupyNew) {
      const resourceTitle = getResourceTitle(event);
      setSelectedEvent({ ...event, resourceTitle });
      setEventDetailsPopoverOpen(true);
    } else {
      setSelectedSlot({
        start: event.start,
        end: event.end,
        resourceId: event.resourceId,
        id: event.id,
      });
      setEventCreatePopoverOpen(true);
    }
  };

  const handleEventDelete = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(intl.formatMessage(messages.confirmDelete))) {
      const { id, microsoftCalUid, resourceId } = selectedEvent;
      deleteCalendarEvent(resourceId, microsoftCalUid || id);
      closePopover('details');
    }
  };

  const handleNavigate = date => {
    setSelectedDate(date);
    setDate(date);

    if (moment(date).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
      const queryParams = new URLSearchParams({
        date: moment(date).format('YYYY-MM-DD'),
      });
      history.replace({ search: queryParams.toString() });
    } else {
      history.replace({ search: '' });
    }
  };

  const eventsToPass = () => {
    if (filteringTime) {
      return !isEmpty(tempEvent)
        ? [...filteredEvents, tempEvent]
        : filteredEvents;
    }
    return !isEmpty(tempEvent) ? [...events, tempEvent] : events;
  };

  useEffect(() => {
    if (buildings.length === 0) {
      fetchBuildings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNow(moment().subtract(1, dateUnitTypes.HOURS).toDate());

    if (filteringTime) {
      const currentDate = moment().toDate();
      handleNavigate(currentDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteringTime]);

  useEffect(() => {
    if (location.search.includes('?date=')) {
      const [, formattedDate] = location.search.split('?date=');
      const date = moment(formattedDate).toDate();

      setSelectedDate(date);
      setDate(date);
    } else setDate(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!location.search.includes('?date=')) {
        // when selected date is today
        const today = new Date();
        if (today.getDate() !== selectedDate.getDate()) {
          setDate(today);
          return setSelectedDate(today);
        }
      }
      return fetchResourceAndEvent();
    }, 300000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  if (isLoading) {
    return (
      <div>
        <CalendarHeader
          partialHeader
          downloadMeetingRoomCsv={downloadMeetingRoomCsv}
          filterByTime={filterByTime}
          buildings={buildings}
          setBuilding={setBuilding}
          selectedBuilding={selectedBuilding}
          date={selectedDate}
          editMode={editMode}
          handleEditModeChange={handleEditModeChange}
          handleSaveChanges={handleSaveChanges}
        />
        <LoadingView />
      </div>
    );
  }

  return (
    <MainWrapper edit={editMode}>
      {resourceMap.length ? (
        <DateHeaderBox
          fullscreenState={fullscreenState}
          today={new Date().getDate() === selectedDate.getDate()}
        >
          <Calendar
            min={
              new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                isSekisuiCompanyUser ? 9 : 0,
                0,
                0,
              )
            }
            max={
              new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                isSekisuiCompanyUser ? 18 : 23,
                isSekisuiCompanyUser ? 0 : 59,
                0,
              )
            }
            localizer={localizer}
            events={eventsToPass()}
            defaultView={Views.DAY}
            views={['day']}
            defaultDate={selectedDate}
            date={selectedDate}
            resources={handleResources()}
            resourceIdAccessor="resourceId"
            resourceTitleAccessor="resourceTitle"
            formats={formats}
            components={{
              // eslint-disable-next-line react/display-name
              toolbar: props => (
                <>
                  {fullscreenState ? (
                    <FullScreenHeader
                      {...props}
                      filteringTime={filteringTime}
                      fullscreenState={fullscreenState}
                    />
                  ) : (
                    <CalendarHeader
                      {...props}
                      filterByTime={filterByTime}
                      downloadMeetingRoomCsv={downloadMeetingRoomCsv}
                      filteringTime={filteringTime}
                      buildings={buildings || []}
                      setBuilding={setBuilding}
                      selectedBuilding={selectedBuilding}
                      editMode={editMode}
                      handleEditModeChange={handleEditModeChange}
                      handleSaveChanges={handleSaveChanges}
                    />
                  )}
                </>
              ),
              // eslint-disable-next-line react/display-name
              resourceHeader: props => (
                <ResourceHeader
                  {...props}
                  isRoomBusy={isRoomBusy}
                  editMode={editMode}
                  handleResourceVisibleChange={handleResourceVisibleChange}
                />
              ),
              event: EventContent,
            }}
            eventPropGetter={eventWrapperStyle}
            slotPropGetter={slotWrapperStyle}
            onNavigate={handleNavigate}
            selectable={!fullscreenState}
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
            scrollToTime={now}
            showMultiDayTimes
          />

          <EventCreatePopover
            open={eventCreatePopoverOpen}
            timeSlot={selectedSlot}
            onConfirm={handleEventReserve}
            onCancel={() => closePopover('create')}
            timeFormat={timeFormat}
            tempEvent={tempEvent}
          />

          <EventDetailsPopover
            open={eventDetailsPopoverOpen}
            selectedEvent={selectedEvent}
            onCancel={() => closePopover('details')}
            timeFormat={timeFormat}
            generateEventStatus={generateEventStatus}
            onDelete={handleEventDelete}
          />
        </DateHeaderBox>
      ) : (
        <>
          <CalendarHeader
            partialHeader
            downloadMeetingRoomCsv={downloadMeetingRoomCsv}
            filterByTime={filterByTime}
            buildings={buildings}
            setBuilding={setBuilding}
            selectedBuilding={selectedBuilding}
            editMode={editMode}
            handleEditModeChange={handleEditModeChange}
            handleSaveChanges={handleSaveChanges}
          />
          <ResourceSettingGuideView />
        </>
      )}
    </MainWrapper>
  );
}

CalendarBody.propTypes = {
  resourceMap: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  events: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  postCalendarEvent: PropTypes.func.isRequired,
  filterByTime: PropTypes.func.isRequired,
  filteringTime: PropTypes.number,
  filteredResources: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  filteredEvents: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  downloadMeetingRoomCsv: PropTypes.func.isRequired,
  setTempEvent: PropTypes.func.isRequired,
  tempEvent: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  isLoading: PropTypes.bool.isRequired,
  deleteCalendarEvent: PropTypes.func.isRequired,
  setDate: PropTypes.func.isRequired,
  fetchBuildings: PropTypes.func.isRequired,
  buildings: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setBuilding: PropTypes.func.isRequired,
  selectedBuilding: PropTypes.oneOfType([PropTypes.object]).isRequired,
  fetchResourceAndEvent: PropTypes.func.isRequired,
  fullscreenState: PropTypes.bool,
};

CalendarBody.defaultProps = {
  filteredResources: [],
  filteredEvents: [],
  filteringTime: null,
  fullscreenState: false,
};
