import { createAction } from '@reduxjs/toolkit';
import * as CONSTANTS from 'containers/Home/constants';

export const getResources = createAction(CONSTANTS.GET_RESOURCES);
export const getEvents = createAction(CONSTANTS.GET_EVENTS, (events, date) => {
  return {
    payload: {
      events,
      date,
    },
  };
});
export const setLoading = createAction(CONSTANTS.SET_LOADING);
export const setCalendarEvent = createAction(CONSTANTS.SET_CALENDAR_EVENT);
export const setMeeting = createAction(CONSTANTS.SET_MEETING);
export const setNewCalendarEvent = createAction(
  CONSTANTS.SET_NEW_CALENDAR_EVENT,
);
export const setFilteringStatus = createAction(CONSTANTS.SET_FILTERING_STATUS);
export const setFilteringTime = createAction(CONSTANTS.SET_FILTERING_TIME);
export const setFilteredResources = createAction(
  CONSTANTS.SET_FILTERED_RESOURCES,
);
export const setFilteredEvents = createAction(CONSTANTS.SET_FILTERED_EVENTS);
export const setTemporaryEvent = createAction(CONSTANTS.SET_TEMPORARY_EVENT);
export const getBuildings = createAction(CONSTANTS.GET_BUILDINGS);
export const setSelectedBuilding = createAction(
  CONSTANTS.SET_SELECTED_BUILDING,
);
export const setSelectedDate = createAction(CONSTANTS.SET_SELECTED_DATE);
export const setSearchDialogOpen = createAction(
  CONSTANTS.SET_SEARCH_DIALOG_OPEN,
);
export const setSearchDialogType = createAction(
  CONSTANTS.SET_SEARCH_DIALOG_TYPE,
);
export const setAvailableTimeSlots = createAction(
  CONSTANTS.SET_AVAILABLE_TIME_SLOTS,
);
