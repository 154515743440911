import React from 'react';
import { Row } from '@d-lighted/design-system';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';

import { HeaderLeft, Icon } from 'components/Header/components/styled';
import RoomsLogoIcon from 'images/img_logo_rooms.svg';
import * as color from 'constants/colors';
import {
  ExitFullScreenButton,
  ExitFullScreenButtonWrapper,
  FullscreenHeaderWrapper,
  TagIndicator,
  TagText,
  TagWrapper,
} from './styled';
import messages from '../messages';

const FullScreenHeader = ({
  fullscreenState,
  partialHeader,
  filteringTime,
}) => {
  const history = useHistory();

  const handleExitFullscreenMode = () => {
    if (fullscreenState) {
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement
      ) {
        const exitFullScreen =
          document.exitFullscreen ||
          document.mozCancelFullScreen ||
          document.webkitExitFullscreen ||
          document.msExitFullscreen;
        exitFullScreen.call(document);
      }
    }
  };

  return (
    <FullscreenHeaderWrapper>
      <HeaderLeft>
        <Icon
          src={RoomsLogoIcon}
          onClick={() => history.push('/')}
          fullscreenState={fullscreenState}
        />
        {partialHeader || (
          <Row
            ml="30px"
            style={{
              flexDirection: 'row',
            }}
          >
            {!filteringTime && (
              <>
                <TagWrapper
                  bg={color.white}
                  border={`solid 0.5px ${color.tagActiveBorder}`}
                  padding="8px 16px"
                >
                  <TagText>
                    <FormattedMessage {...messages.vacancy} />
                  </TagText>
                </TagWrapper>
                <TagWrapper
                  border={`solid 0.5px ${color.busyBGGray}`}
                  bg={color.busyBGGray}
                  ml="12px"
                  padding="8px 16px"
                >
                  <TagText>
                    <FormattedMessage {...messages.inUse} />
                  </TagText>
                </TagWrapper>
              </>
            )}
            <TagWrapper ml="30px">
              <TagIndicator bg={color.doneEventGray} />
              <TagText>
                <FormattedMessage {...messages.checkedOut} />
              </TagText>
            </TagWrapper>
            <TagWrapper ml="24px">
              <TagIndicator
                bg={color.busyEvent}
                border={`solid 0.5px ${color.busyEvent}`}
              />
              <TagText>
                <FormattedMessage {...messages.inAMeeting} />
              </TagText>
            </TagWrapper>
            <TagWrapper ml="30px">
              <TagIndicator
                bg={color.incomingEvent}
                border={`solid 0.5px ${color.incomingEvent}`}
              />
              <TagText>
                <FormattedMessage {...messages.reserved} />
              </TagText>
            </TagWrapper>
          </Row>
        )}
      </HeaderLeft>
      <ExitFullScreenButtonWrapper>
        <ExitFullScreenButton onClick={() => handleExitFullscreenMode()}>
          <FormattedMessage {...messages.endFullScreen} />
        </ExitFullScreenButton>
      </ExitFullScreenButtonWrapper>
    </FullscreenHeaderWrapper>
  );
};

FullScreenHeader.defaultProps = {
  fullscreenState: false,
  partialHeader: false,
  filteringTime: null,
};

FullScreenHeader.propTypes = {
  fullscreenState: PropTypes.bool,
  partialHeader: PropTypes.bool,
  filteringTime: PropTypes.number,
};

export default FullScreenHeader;
