export function getCurrentUser() {
  return JSON.parse(localStorage.getItem('currentUser')) || {};
}

export function setCurrentUser(user) {
  localStorage.setItem('currentUser', JSON.stringify(user));
  return user;
}

export function overrideCurrentUserCompany(newCompany) {
  const currentUser = getCurrentUser();

  currentUser.company = newCompany;
  setCurrentUser(currentUser);
}

export function getSubdomain() {
  const currentUser = getCurrentUser();

  return `${
    currentUser?.company?.custom_subdomain_webapp != null
      ? `${currentUser?.company?.custom_subdomain_webapp}.`
      : ''
  }`;
}

export function getQRCodeAllowed() {
  let result = true;
  const currentUser = getCurrentUser();

  const meetingRoomProduct = (currentUser?.company?.products || []).find(
    product => product.product_type === 'meetingroom',
  );

  const qrCodeAllowed =
    meetingRoomProduct?.product_meetingroom?.qr_code_allowed;
  if (typeof qrCodeAllowed !== 'undefined') {
    result = qrCodeAllowed;
  }
  return result;
}

export function getPlan() {
  const currentUser = getCurrentUser();
  const finalProduct = (currentUser?.company?.products || []).find(
    product => product.product_type === 'meetingroom',
  );

  if (currentUser == null || finalProduct == null) return null;
  return finalProduct?.configuration?.plan_status;
}

export function isAdmin() {
  return (
    getCurrentUser()?.privilege === 'admin' ||
    getCurrentUser()?.privilege === 'primary_admin' ||
    getCurrentUser()?.admin
  );
}
