import React, { useState } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import 'moment/locale/ja';
import { Row, Col, IconButton } from '@d-lighted/design-system';

import * as calendarNavigationTypes from 'constants/calendarNavigationTypes';
import * as color from 'constants/colors';
import * as dateUnitTypes from 'constants/dateUnitTypes';
import { HeadingMonth, DateSelector, Day, Date } from './styled';

const StickyRow = styled.div`
  position: sticky;
  top: 0;
  background: ${color.white};
  z-index: 15;
`;

const ArrowButtonWrapper = styled(IconButton)`
  width: 6vw;
  padding: 0;
  min-width: 30px;
  ${({ disable }) => {
    return disable
      ? `
     pointer-events: none;
     color: ${color.grayShade3};
     `
      : '';
  }}
`;

export function CalendarHeader({ onNavigate }) {
  const currentDate = moment();
  const [activeDate, setActiveDate] = useState(moment());
  const [weekOfYear, setWeekOfYear] = useState(moment().week());

  const returnDaysOfWeek = startOfWeek =>
    [...Array(7)].map((el, index) => {
      const startDate = moment(startOfWeek);
      return startDate.add(index, dateUnitTypes.DAYS);
    });

  const [daysOfWeek, setDaysOfWeek] = useState(
    returnDaysOfWeek(moment().startOf('week')),
  );

  const displayNewWeek = newWeekOfYear => {
    setWeekOfYear(newWeekOfYear);
    const startOfWeek = moment().week(newWeekOfYear).startOf('week');
    const days = returnDaysOfWeek(startOfWeek);
    setDaysOfWeek(days);
  };

  const handleNextClick = () => {
    displayNewWeek(weekOfYear + 1);
  };

  const handlePrevClick = () => {
    displayNewWeek(weekOfYear - 1);
  };

  return (
    <StickyRow>
      <Row ml="9px" mr="9px" alignItems="center">
        <Col pr="0">
          <HeadingMonth>{daysOfWeek[0].format('YYYY/MM')}</HeadingMonth>
        </Col>
      </Row>

      <Row alignItems="center" justifyContent="center" flexWrap="nowrap">
        <Col pl="0" pr="0">
          <ArrowButtonWrapper
            disable={currentDate.isSame(daysOfWeek[0], 'week')}
            onClick={handlePrevClick}
          >
            <i className="fa fa-angle-left" aria-hidden="true" />
          </ArrowButtonWrapper>
        </Col>

        {daysOfWeek.map(day => {
          const isSelected = day.isSame(activeDate, 'day');
          const isCurrent = day.isSame(currentDate, 'day');
          const isPast = day.isBefore(currentDate, 'day');

          return (
            <Col key={day.toDate()} padding="0">
              <DateSelector
                isSelected={isSelected}
                isCurrent={isCurrent}
                isPast={isPast}
                onClick={() => {
                  setActiveDate(day);
                  onNavigate(calendarNavigationTypes.DATE, day.toDate());
                }}
              >
                <Row height="100%">
                  <Col
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    padding="0"
                  >
                    <Day
                      isSelected={isSelected}
                      isCurrent={isCurrent}
                      isPast={isPast}
                    >
                      {day.locale('ja').format('ddd')}
                    </Day>
                    <Date
                      isSelected={isSelected}
                      isCurrent={isCurrent}
                      isPast={isPast}
                    >
                      {day.format('DD')}
                    </Date>
                  </Col>
                </Row>
              </DateSelector>
            </Col>
          );
        })}

        <Col pr="0" pl="0">
          <ArrowButtonWrapper onClick={handleNextClick}>
            <i className="fa fa-angle-right" aria-hidden="true" />
          </ArrowButtonWrapper>
        </Col>
      </Row>
    </StickyRow>
  );
}

CalendarHeader.propTypes = {
  onNavigate: PropTypes.func.isRequired,
};
