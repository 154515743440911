import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import BigCalendar from 'components/BigCalendar';
import { isMobileDevice } from 'utils/mobileUtils';
import ResourcesList from './components/ResourcesList';
import { Wrapper, MobileWrapper } from './components/styled';
import { useGetResourceEvent } from './hooks/useGetResourceEvent';
import { useBuildings } from './hooks/useBuildings';

const Home = ({ intl, useNotify, fullscreenState }) => {
  const notify = useNotify(intl);

  const {
    resources,
    events,
    isLoading,
    getCalendarEventDetails,
    calendarEvent,
    postCheckIn,
    putCheckOut,
    postInstantMeeting,
    postCalendarEvent,
    newCalendarEvent,
    filterByTime,
    filteredResources,
    filteringTime,
    filteredEvents,
    downloadMeetingRoomCsv,
    setTempEvent,
    tempEvent,
    deleteCalendarEvent,
    selectedDate,
    setDate,
    searchTimeSlots,
    availableTimeSlots,
    forceGetResourceAndEvent,
  } = useGetResourceEvent(notify);

  const {
    fetchBuildings,
    buildings,
    setBuilding,
    selectedBuilding,
  } = useBuildings();

  if (!isMobileDevice()) {
    return (
      <Wrapper fullscreenState={fullscreenState}>
        <BigCalendar
          resourceMap={resources}
          events={events}
          postCalendarEvent={postCalendarEvent}
          newCalendarEvent={newCalendarEvent}
          filterByTime={filterByTime}
          filteredResources={filteredResources}
          filteringTime={filteringTime}
          filteredEvents={filteredEvents}
          downloadMeetingRoomCsv={downloadMeetingRoomCsv}
          setTempEvent={setTempEvent}
          tempEvent={tempEvent}
          isLoading={isLoading}
          deleteCalendarEvent={deleteCalendarEvent}
          setDate={setDate}
          selectedDate={selectedDate}
          fetchBuildings={fetchBuildings}
          buildings={buildings}
          setBuilding={setBuilding}
          selectedBuilding={selectedBuilding}
          fetchResourceAndEvent={forceGetResourceAndEvent}
          fullscreenState={fullscreenState}
        />
      </Wrapper>
    );
  }

  return (
    <MobileWrapper>
      <ResourcesList
        resources={resources}
        events={events}
        getCalendarEventDetails={getCalendarEventDetails}
        calendarEvent={calendarEvent}
        postCheckIn={postCheckIn}
        putCheckOut={putCheckOut}
        postInstantMeeting={postInstantMeeting}
        fetchBuildings={fetchBuildings}
        buildings={buildings}
        setBuilding={setBuilding}
        selectedBuilding={selectedBuilding}
        setDate={setDate}
        isLoading={isLoading}
        searchTimeSlots={searchTimeSlots}
        availableTimeSlots={availableTimeSlots}
        fetchResourceAndEvent={forceGetResourceAndEvent}
      />
    </MobileWrapper>
  );
};

Home.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  useNotify: PropTypes.func.isRequired,
  fullscreenState: PropTypes.bool,
};

Home.defaultProps = {
  fullscreenState: false,
};

export default injectIntl(Home);
