import { createReducer } from '@reduxjs/toolkit';
import {
  getResources,
  getEvents,
  setLoading,
  setCalendarEvent,
  setMeeting,
  setNewCalendarEvent,
  setFilteringStatus,
  setFilteringTime,
  setFilteredResources,
  setFilteredEvents,
  setTemporaryEvent,
  getBuildings,
  setSelectedBuilding,
  setSelectedDate,
  setSearchDialogOpen,
  setSearchDialogType,
  setAvailableTimeSlots,
} from 'containers/Home/actions';
import { differentDay, getStartDate, getEndDate } from 'utils/momentUtils';

export const FILTERING_STATUS_CONSTANTS = {
  ALL: 'all',
  VACANT: 'vacant',
  PERIOD: 'period',
};

const INITIAL_STATUS = {
  resources: [],
  events: [],
  isLoading: true,
  calendarEvent: {},
  meeting: {},
  newCalendarEvent: {},
  filteringStatus: FILTERING_STATUS_CONSTANTS.ALL,
  filteringTime: null,
  filteredResources: [],
  filteredEvents: [],
  temporaryEvent: {},
  buildings: [],
  selectedBuilding:
    (localStorage.getItem('selectedBuilding') &&
      JSON.parse(localStorage.getItem('selectedBuilding'))) ||
    {},
  selectedDate: null,
  searchDialogOpen: false,
  searchDialogType: '',
  availableTimeSlots: [],
};

const resourceEventReducer = createReducer(INITIAL_STATUS, builder => {
  builder.addCase(getResources, (state, action) => {
    state.resources = action.payload;
  });
  builder.addCase(getEvents, (state, action) => {
    const { events, date } = action.payload;
    state.events = events.map(event => {
      if (differentDay(event.start, event.end)) {
        event.start = getStartDate(event.start, date).toDate();
        event.end = getEndDate(event.end, date).toDate();
      }
      return event;
    });
  });
  builder.addCase(setLoading, (state, action) => {
    state.isLoading = action.payload;
  });
  builder.addCase(setCalendarEvent, (state, action) => {
    state.calendarEvent = action.payload;
  });
  builder.addCase(setMeeting, (state, action) => {
    state.meeting = action.payload;
  });
  builder.addCase(setNewCalendarEvent, (state, action) => {
    state.newCalendarEvent = action.payload;
  });
  builder.addCase(setFilteringStatus, (state, action) => {
    state.filteringStatus = action.payload;
  });
  builder.addCase(setFilteringTime, (state, action) => {
    state.filteringTime = action.payload;
  });
  builder.addCase(setFilteredResources, (state, action) => {
    state.filteredResources = action.payload;
  });
  builder.addCase(setFilteredEvents, (state, action) => {
    state.filteredEvents = action.payload;
  });
  builder.addCase(setTemporaryEvent, (state, action) => {
    state.temporaryEvent = action.payload;
  });
  builder.addCase(getBuildings, (state, action) => {
    state.buildings = action.payload;
  });
  builder.addCase(setSelectedBuilding, (state, action) => {
    state.selectedBuilding = action.payload;
  });
  builder.addCase(setSelectedDate, (state, action) => {
    state.selectedDate = action.payload;
  });
  builder.addCase(setSearchDialogOpen, (state, action) => {
    state.searchDialogOpen = action.payload;
  });
  builder.addCase(setSearchDialogType, (state, action) => {
    state.searchDialogType = action.payload;
  });
  builder.addCase(setAvailableTimeSlots, (state, action) => {
    state.availableTimeSlots = action.payload;
  });
});

export default resourceEventReducer;
