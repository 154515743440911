import styled, { css } from 'styled-components';
import QrReader from 'react-qr-reader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Row,
  Col,
  Modal,
  Label,
  Input,
  DefaultSelect,
} from '@d-lighted/design-system';
import * as colors from 'constants/colors';
import check from 'images/check.svg';

export const DragBar = styled.div`
  background-color: ${colors.grayShade3};
  width: 48px;
  height: 6px;
  border-radius: 4px;
  margin-bottom: 16px;
`;

export const GrayIcon = styled(FontAwesomeIcon)`
  color: ${colors.grayThin3};
`;

export const BrandBlueIcon = styled(FontAwesomeIcon)`
  color: ${colors.brandBlue};
  width: 16px;
  height: 17px;
`;

export const TitleRow = styled(Row)`
  width: 100%;
  padding: 0 0 18px 0;
`;

export const SortingTitle = styled.p`
  color: ${colors.brandBlue};
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding-left: 10px;
`;

export const CenteredCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexEndCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const MainContentsWrapper = styled.div`
  width: 100%;
  margin-bottom: 88px;
`;

export const ResourceRow = styled(Row)`
  background-color: white;
  margin: 5px 0;
  display: flex;
  padding: 10px;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 0 5px lightgrey;
  width: 100%;
  cursor: pointer;
  min-height: 73px;
  .empty {
    border: 1px solid ${colors.brandBlue};
    background-color: ${colors.white};
    color: ${colors.brandBlue};
  }
  .error {
    background-color: ${colors.lighterRed};
    color: ${colors.errorRed};
  }
`;

export const Pill = styled.div`
  padding: 5px 10px;
  width: fit-content;
  border-radius: 5px;
  font-size: 10px;
`;

export const ResourceTitle = styled.p`
  color: ${colors.darkBlue};
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  overflow-wrap: break-word;
  max-width: 85vw;
`;

export const EntryButtonCol = styled(Col)`
  text-align: right;
`;

export const EntryButton = styled(Button)`
  border-radius: 100px;
  border: 2px solid ${colors.brandBlue};
  color: ${colors.brandBlue};
  font-weight: 600;
  width: 88px;
  height: 40px;
`;

export const RoomCol = styled(Col)`
  background-color: transparent;
  border-radius: 10px;
  padding: 13px 20px;
  min-height: 55px;
  border: 0px;
  display: flex;
  justify-content: center;
`;

export const RoomTitleLabel = styled(Label)`
  font-size: 18px;
  color: ${colors.deepBlue};
  font-weight: 500;
  line-height: 29px;
`;

export const CustomForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
`;

export const InputField = styled(Input)`
  height: 55px;
  width: 77vw;
  background: ${colors.lightGray};
  border-radius: 10px;
  border: 0px solid ${colors.lightGray};
  padding: 12px 20px;
  font-size: 18px;
  color: ${colors.grayThin3};
  font-weight: 500;
  line-height: 29px;
`;

export const StyledTimeSelect = styled.select`
  height: 55px;
  width: 91px;
  background: ${colors.lightGray};
  border-radius: 10px;
  border: 0px solid ${colors.lightGray};
  padding: 12px 20px;
  font-size: 18px;
  color: ${colors.deepBlue};
  font-weight: 500;
  line-height: 29px;
  margin-right: 19px;
`;

export const CardFirstRow = styled(Row)`
  padding-bottom: 7px;
`;

export const CardSecondaryText = styled.p`
  color: ${colors.grayThin3};
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
`;

export const DayCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  margin-bottom: 10px;
  background: ${colors.white};
  border: 1px solid ${colors.lightGray};
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(20, 44, 43, 0.06);
  border-radius: 10px;
`;

export const DayCircle = styled.div`
  width: 50px;
  height: 50px;
  background: ${colors.grayInput};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 590;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.darkBlue};
`;

export const DayText = styled.p`
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: ${colors.darkBlue};
`;

export const TimeListWrapper = styled.div`
  margin: 4px 0 6px;
  width: 100%;
`;

export const TimeRangeText = styled.p`
  font-weight: 590;
  font-size: 26px;
  line-height: 31px;
  color: ${colors.darkBlue};
`;

export const RoomDetailLabel = styled(Label)`
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: ${colors.grayThin3};
`;

export const ReserveButton = styled(Button)`
  height: 42px;
  min-width: auto;
  background-color: ${colors.brandBlue};
  border: 2px solid ${colors.brandBlue};
  border-radius: 100px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
`;

export const EmptySearchResultWrapper = styled.div`
  text-align: center;
  color: ${colors.grayThin3};
  height: calc(100vh - (65px + 35px + 90px));
  display: flex;
  align-items: center;
`;

export const CustomSearchModal = styled(Modal)`
  z-index: 43;
  border-radius: 0;
  .pad_bot {
    padding-bottom: calc(77px + 88px);
    height: auto;
  }
`;

export const CustomConfirmationModal = styled(Modal)`
  border-radius: 20px;
  overflow-y: ${props => (props.overFlow ? 'auto' : null)};
  .space_evenly {
    justify-content: space-evenly;
  }
`;

export const ConfirmationContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  position: relative;
  .confirm_button {
    background-color: ${colors.brandBlue};
  }
  .cancel_button {
    color: ${colors.brandBlue} !important;
    border: 2px solid ${colors.brandBlue};
  }
  .space_top {
    padding: 24px 0;
  }
`;

export const ConfirmationTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: ${props => props.fontSize || '22px'};
  line-height: ${props => props.lineHeight || '36px'};
  color: ${props => props.color || colors.deepBlue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 12px;
  padding: ${props => props.padding};
  text-align: center;
`;

export const ConfirmationDialogButton = styled(Button)`
  width: 280px;
  height: 47px;
  border-radius: 24px;
  font-weight: 600;
  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export const CancelDialogButton = styled(Button)`
  width: 280px;
  height: 47px;
  border-radius: 24px;
  font-weight: 600;
  color: ${colors.brandBlue};
  border: 2px solid ${colors.brandBlue};
`;

export const ChoiceRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 20px;
  cursor: pointer;
  padding-top: ${props => props.pt};

  // Filter calculated with https://codepen.io/sosuke/pen/Pjoqqp from color #15B9CB
  ${props =>
    props.checked &&
    `
  &:after {
     content: url(${check});
     transform: scale(0.7);
     margin-top: -6px;
     filter: invert(85%) sepia(47%) saturate(4665%) hue-rotate(135deg)
       brightness(85%) contrast(85%);
  }
`}
`;

export const SearchLabel = styled(Label)`
  color: ${colors.brandBlue};
  display: block;
  padding: 4px 16px 4px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.01em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SortingForm = styled.form`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 26px 24px 0;
  width: 100%;
`;

export const SortingFooterContainer = styled.div`
  background: ${colors.white};
  box-shadow: 0px -5px 14px rgba(0, 0, 0, 0.09);
  border-radius: 20px 20px 0px 0px;
  position: fixed;
  width: 100vw;
  height: 77px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 40;
`;

export const TimeSelector = styled.div`
  ${'' /* width: 48px; */}
  height: 36px;
  background: ${props => (props.isSelected ? colors.brandBlue : colors.white)};
  border-radius: 36px;
  border: 1px solid
    ${props =>
      props.isSelected ? colors.todayIndicator : colors.tagInactiveBorder};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MeetingTime = styled.p`
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: ${props => (props.isSelected ? colors.white : colors.doneEventGray)};
`;

export const MinuteSelect = styled(DefaultSelect)`
  height: 44px;
  border-color: ${colors.tagActiveBorder};
  margin: 0;
  font-weight: normal;
`;

export const DatePickerContainer = styled.div`
  padding-top: 20px;
`;

export const QRContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 96%;
  width: 100%;
`;

export const QRClose = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 30px;
  z-index: 42;
`;

export const QRCameraSwitch = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  z-index: 9;
`;

export const QRHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 3%;
  z-index: 1;
`;

export const QRHeader = styled(Label)`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  padding-top: 20px;
`;

export const QRScanLabel = styled(Label)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: ${colors.lightGray};
  width: calc(100% - 92px);
  padding-top: 8px;
`;

export const StyledQrReader = styled(QrReader)`
  width: 100%;
  height: 96%;
  display: flex;
  section {
    border-radius: 0px;
  }
  section > div {
    border: none !important;
    box-shadow: none !important;
  }
`;

export const QRDialogContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CircleIcon = styled.div`
  width: 82px;
  height: 82px;
  border-radius: 56px;
  background: ${props => (props.success ? colors.brandBlue : colors.darkRed)};
  box-shadow: 0px 10px 30px
    ${props => (props.success ? colors.transparentBlue : colors.transparentRed)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainStatusText = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: ${colors.darkText};
  text-align: center;
`;

export const SubText = styled(Label)`
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: ${colors.grayThin3};
  text-align: center;
  display: inline-flex;
`;

export const FetchIndicatorWrapper = styled.div`
  top: 50%;
  left: 50%;
  display: flex;
  transform: translate(-50%, -50%);
  position: absolute;
  margin-bottom: 88px;
  &:before {
    background: #000;
  }
`;

const sharedStyle = css`
  margin: 3px;
  width: 17px;
  height: 17px;
  background-color: ${colors.brandBlue};
  border-radius: 50%;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
`;

export const DivOne = styled.div`
  ${sharedStyle}
  animation-name: load-one;

  @keyframes load-one {
    30% {
      transform: translateY(-50%);
    }
  }
`;

export const DivTwo = styled.div`
  ${sharedStyle}
  animation-name: load-two;

  @keyframes load-two {
    50% {
      transform: translateY(-50%);
    }
  }
`;

export const DivThree = styled.div`
  ${sharedStyle}
  animation-name: load-three;

  @keyframes load-three {
    70% {
      transform: translateY(-50%);
    }
  }
`;

export const FetchingIcon = styled.img`
  width: 111px;
  height: 111px;
  margin-top: 39px;
`;
