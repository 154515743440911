import { defineMessages } from 'react-intl';

export default defineMessages({
  email: {
    id: 'validation.email',
    defaultMessage: 'Please enter a valid email address.',
  },
  required: {
    id: 'validation.required',
    defaultMessage: 'Required field.',
  },
  duration: {
    id: 'validation.duration',
    defaultMessage: 'Value must be between 15 and 285 minutes',
  },
  dateArray: {
    id: 'validation.dateArray',
    defaultMessage: 'Select another valid date',
  },
});
