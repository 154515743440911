import React, { useState, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';

import * as domUtil from 'utils/domUtil';
import { Wrapper } from './styled';

export default function PopoverViewPositionProvider({ render, ...props }) {
  const HEADER_OFFSET = 80;
  const POSITION_TARGET_KEYS = {
    CLASS_NAME: 'className',
    ID: 'id',
  };

  const [dimension, setDimension] = useState({
    top: 0,
    left: 0,
    height: 'auto',
    maxHeight: window.innerHeight,
  });

  const node = useRef(null);

  const getElement = (key, value) => {
    if (key === POSITION_TARGET_KEYS.CLASS_NAME) {
      return domUtil.selectTargetElement(value);
    }
    return document.getElementById(value);
  };

  const handleChangePosition = (targetKey, targetValue) => {
    const windowWidth = window.innerWidth;
    const targetElement = getElement(targetKey, targetValue);
    const targetRect = targetElement.getBoundingClientRect();
    const verticalOffset = 20;
    let modifiedTop = targetRect.top;
    const popOverPosition = node.current.getBoundingClientRect();
    if (window.innerHeight < popOverPosition.height + targetRect.top) {
      const diff = popOverPosition.height + targetRect.top - window.innerHeight;
      modifiedTop -= diff + 20;
    }
    const leftSpace = targetRect.left - verticalOffset;
    const rightSpaceAsLeft = targetRect.right + verticalOffset;
    const leftPosition = leftSpace - popOverPosition.width;
    const rightPosition = rightSpaceAsLeft;
    const rightSpace = windowWidth - rightSpaceAsLeft;
    const positionLeft = leftSpace > rightSpace ? leftPosition : rightPosition;
    setDimension({
      ...dimension,
      top: Math.max(HEADER_OFFSET, modifiedTop),
      left: positionLeft,
    });
  };

  useEffect(() => {
    const { positionTargetKey, positionTargetValue } = props;
    handleChangePosition(positionTargetKey, positionTargetValue);
    return () => {
      setDimension({ ...dimension, top: 0, left: 0, height: 'auto' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line consistent-return
  const handleChangeContentHeight = () => {
    const { positionTargetKey, positionTargetValue } = props;
    const targetElement = getElement(positionTargetKey, positionTargetValue);
    if (!targetElement) return null;
    const targetElementDimension = targetElement.getBoundingClientRect();
    const popoverDimension = document
      .querySelector('.popover-view')
      .getBoundingClientRect();
    const popoverVerticalPosition = dimension.top + popoverDimension.height;
    if (popoverDimension.height > window.innerHeight) {
      setDimension({
        ...dimension,
        top: 40,
        height: window.innerHeight - 80,
        transition: true,
      });
    } else if (popoverDimension > window.innerHeight) {
      const invisibleHeight = popoverVerticalPosition - window.innerHeight;
      setDimension({
        ...dimension,
        top: dimension.top - (invisibleHeight + 20),
        height: 'auto',
        transition: true,
      });
    } else {
      const diff =
        popoverDimension.height +
        targetElementDimension.top -
        window.innerHeight;
      setDimension({
        ...dimension,
        top: targetElementDimension.top - (diff + 20),
        height: 'auto',
        transition: false,
      });
      handleChangePosition(positionTargetKey, positionTargetValue);
    }
  };

  return (
    <Wrapper
      ref={node}
      top={dimension.top}
      left={dimension.left}
      height={dimension.height}
      maxHeight={dimension.maxHeight}
    >
      {render({
        changeContentHeight: handleChangeContentHeight,
      })}
    </Wrapper>
  );
}

PopoverViewPositionProvider.propTypes = {
  positionTargetKey: PropTypes.string.isRequired,
  positionTargetValue: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
};
