import styled from 'styled-components';
import { Modal, Button } from '@d-lighted/design-system';
import * as color from 'constants/colors';
import { fade } from 'components/Animation';

export const BuildingText = styled.p`
  font-size: 15px;
  color: ${color.textBlack};
  display: inline;
`;

export const SelectedBuildingText = styled.p`
  font-size: 15px;
  color: ${color.primaryGreen};
  text-decoration: underline;
  cursor: pointer;
  display: inline;
`;

export const CustomBuildingModal = styled(Modal)`
  border-radius: 6px;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
`;

export const ModalWrapper = styled.div`
  width: 500px;
`;

export const ModalHeading = styled.div`
  position: relative;
  padding: 15px;
  text-align: center;
  border-bottom: ${props => (props.border ? props.border : '')};
  > h3 {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 200;
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  display: block;
  right: 0px;
  top: 20px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: ${color.white};
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 10px;
    right: 10px;
    font-size: 20px;
    background-color: ${color.primaryGreen};
    width: 25px;
    height: 2px;
    transform: rotate(45deg);
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 10px;
    right: 10px;
    font-size: 20px;
    background-color: ${color.primaryGreen};
    width: 25px;
    height: 2px;
    transform: rotate(-45deg);
  }
`;

export const ModalBody = styled.div`
  width: auto;
  padding: 40px 38px;
`;

export const BuildingsWrapper = styled.div`
  max-height: 54vh;
  overflow: scroll;
  margin-bottom: 20px;
`;

export const BuildingRow = styled.div`
  display: flex;
  position: relative;
  padding: 8px;
  border-bottom: 1px solid ${color.grayShade};
  animation: ${fade} 300ms ease;
  border-radius: 3px;
  align-items: center;
  justify-content: space-between;
`;

export const SelectButton = styled(Button)`
  width: auto;
  min-width: 80px;
  animation: ${fade} 300ms ease;
  box-sizing: border-box;
  cursor: pointer;
  height: 40px;
  background-color: ${color.primaryGreen};
  color: ${color.white};
  border-radius: 3px;
`;

export const SelectedButton = styled(Button)`
  width: auto;
  min-width: 80px;
  animation: ${fade} 300ms ease;
  box-sizing: border-box;
  cursor: pointer;
  height: 40px;
  background-color: ${color.white};
  color: ${color.grayThin2};
  border-radius: 3px;
  border: 1px solid;
  :hover {
    background-color: inherit;
  }
`;

export const SecondaryButton = styled(Button)`
  color: ${color.white};
  border: solid 1px ${color.grayThin};
  background: ${color.grayThin};
  height: 46px;
  border-radius: 3px;
  :hover {
    background-color: ${color.grayThin};
  }
`;
