import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';

import Text from 'components/Texts/Text14';
import messages from 'components/BigCalendar/messages';
import { getPlan } from 'utils/userUtils';
import {
  DropDownContentWrapper,
  DropDownItem,
  GearButton,
  IconWrapper,
  SaveButton,
} from './styled';

const DisplaySettingsButton = ({
  fullscreenState,
  editMode,
  handleEditModeChange,
  handleSaveChanges,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const planStatus = getPlan();

  const handleClickButton = () => {
    setDropdown(!dropdown);
  };

  const handleClickOutside = event => {
    const targetElement = event.target;
    if (
      !(
        targetElement?.matches('#dropdownBtn') ||
        targetElement?.parentElement?.matches('#dropdownBtn')
      )
    )
      setDropdown(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleFullScreenChange = () => {
    if (!fullscreenState) {
      document.documentElement.requestFullscreen();
    }
  };

  const items = [
    {
      id: 2,
      text: <FormattedMessage {...messages.selectTheMeetingRoomToDisplay} />,
      onClick: () => handleEditModeChange(),
    },
  ];

  if (planStatus === 'premium' || planStatus === 'trial') {
    items.unshift({
      id: 1,
      text: <FormattedMessage {...messages.fullScreen} />,
      onClick: () => handleFullScreenChange(),
    });
  }

  const onSaveChangesClick = () => {
    handleSaveChanges();
    handleEditModeChange();
  };

  return (
    <>
      {!editMode ? (
        <div style={{ position: 'relative' }}>
          <GearButton
            id="dropdownBtn"
            className="dropdown-button"
            onClick={() => handleClickButton()}
          >
            <FormattedMessage {...messages.displaySettings} />
            <IconWrapper>
              <i className="fa fa-cog" aria-hidden="true" />
            </IconWrapper>
          </GearButton>
          {dropdown && (
            <DropDownContentWrapper duration="0.2s">
              {items.map(item => (
                <DropDownItem onClick={() => item.onClick()} key={item.id}>
                  <Text>{item.text}</Text>
                </DropDownItem>
              ))}
            </DropDownContentWrapper>
          )}
        </div>
      ) : (
        <SaveButton onClick={() => onSaveChangesClick()}>
          <FormattedMessage {...messages.saveYourChanges} />
        </SaveButton>
      )}
    </>
  );
};

DisplaySettingsButton.defaultProps = {
  fullscreenState: false,
  editMode: false,
};

DisplaySettingsButton.propTypes = {
  fullscreenState: PropTypes.bool,
  editMode: PropTypes.bool,
  handleEditModeChange: PropTypes.func.isRequired,
  handleSaveChanges: PropTypes.func.isRequired,
};

export default DisplaySettingsButton;
