import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import axios from 'setupAxios';
import { setCurrentUser } from 'utils/userUtils';
import { pushUserIdToPageViewGA } from 'utils/gtmUtils';
import messages from './messages';
import { setPromotionLink, setPromotionTitle } from './actions';

export function useSignIn({ notify, setLocale }, setUser, state) {
  const dispatch = useDispatch();
  const history = useHistory();
  const promotionLinkState = useSelector(
    reducerState => reducerState.signIn?.promotionLink,
  );

  const promotionTitleState = useSelector(
    reducerState => reducerState.signIn?.promotionTitle,
  );

  const promotionParams = {
    method: 'GET',
    dataType: 'text',
    mode: 'cors',
    headers: {
      Accept: 'text/plain',
      'Content-Type': 'text/plain',
    },
  };

  async function signIn(values) {
    dispatch(showLoading());
    const params = { sign_in_from: 'scheduling_web', ...values };
    const response = await axios
      .post('/auth/sign_in', params)
      .catch(e => e.response);

    if (response?.status !== 200) {
      notify.setError(messages.notifyError, response?.data?.error?.message);
      dispatch(hideLoading());
    } else {
      pushUserIdToPageViewGA();
      const { data } = response;
      const currentUser = setCurrentUser(data.data);
      setLocale(currentUser.company.locale_code);
      setUser(data?.data);
      history.push('/', state && state);
      dispatch(hideLoading());
    }
  }

  async function validateOnetimeToken(token, client) {
    const params = {
      token,
      client_id: client,
    };
    const response = await axios
      .put('/auth/one_time_token', params)
      .catch(e => e.response);

    if (response.status !== 200) {
      // ERROR
    } else {
      const { data } = response;
      const currentUser = setCurrentUser(data.data);
      setLocale(currentUser.company.locale_code);
      setUser(data?.data);
      history.push('/', state && state);
    }
  }

  async function fetchPromotionLink() {
    dispatch(showLoading());
    const path = `${window.location.origin}/promotion/link.txt`;

    try {
      const response = await axios.get(path, promotionParams).then(res => {
        return res;
      });
      if (response?.data.includes('<!DOCTYPE html>')) {
        dispatch(hideLoading());
      } else {
        dispatch(setPromotionLink(response?.data));
        dispatch(hideLoading());
      }
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  }

  async function fetchPromotionTitle() {
    dispatch(showLoading());
    const path = `${window.location.origin}/promotion/title.txt`;
    try {
      const response = await axios.get(path, promotionParams).then(res => {
        return res;
      });
      if (response?.data.includes('<!DOCTYPE html>')) {
        dispatch(hideLoading());
      } else {
        dispatch(setPromotionTitle(response?.data));
        dispatch(hideLoading());
      }
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  }

  return {
    signIn,
    promotionLinkState,
    promotionTitleState,
    fetchPromotionLink,
    fetchPromotionTitle,
    validateOnetimeToken,
  };
}
